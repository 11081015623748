import angular from 'angular';

/**
 * @constructor
 *
 * @description
 *
 * Angular filter definition function.
 *
 * @returns {angular.Filter} Angular filter function.
 *
 * @ngInject
 */
export function sortLegendFilter() {
  const compare = function (a, b) {
    if (a['SubTheme'] < b['SubTheme']) {
      return -1;
    }
    if (a['SubTheme'] > b['SubTheme']) {
      return 1;
    }
    if ((a['AreaShare'] || 0) > (b['AreaShare'] || 0)) {
      return -1;
    }
    if ((a['AreaShare'] || 0) < (b['AreaShare'] || 0)) {
      return 1;
    }
    if ((a['LengthShare'] || 0) > (b['LengthShare'] || 0)) {
      return -1;
    }
    if ((a['LengthShare'] || 0) < (b['LengthShare'] || 0)) {
      return 1;
    }
    return 0;
  };

  return function (input) {
    if (Array.isArray(input)) {
      return input.sort(compare);
    }
    return input;
  };
}

/**
 * @type {angular.IModule}
 * @hidden
 *
 * @description
 *
 * Sorts the legend elements respecting their sub theme, area and length.
 */
const myModule = angular.module('sortLegend', []);
myModule.filter('sortLegend', sortLegendFilter);

export default myModule;
