import angular from 'angular';
import Extract from '../service/extract.js';
import replaceFilter from '../filters/replace.js';
import {oerebEventExtractLoaded} from '../component.js';

export class realEstateController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {import("../service/extract.js").ExtractService} Extract Angular service for extract loading.
   * @ngInject
   */

  constructor($scope, Extract) {
    this.data = {};

    // Update real estate data on loaded extract
    $scope.$on(oerebEventExtractLoaded, () => {
      this.data = Extract.getRealEstate();
      // TODO 18-04-2023 cvr: backward compatibility v1
      if (this.data?.MunicipalityName == undefined) {
        this.data.MunicipalityName = this.data.Municipality;
      }
    });
  }
}

const realEstateComponent = {
  controller: realEstateController,
  template: require('./real_estate.html'),
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('agrolaOerebRealEstate', [Extract.name, replaceFilter.name]);
myModule.component('agrolaOerebRealEstate', realEstateComponent);

export default myModule;
