import angular from 'angular';
import multilingualTextFilter from '../filters/multilingual_text.js';
export class ExtractService {
  constructor(multilingualTextFilter) {
    this.extract_ = undefined;

    this.embeddable_ = undefined;

    this.filterThemeTextsByLanguage = function (themes) {
      if (themes == undefined) {
        return undefined;
      }
      for (const theme of themes) {
        theme.Text = multilingualTextFilter(theme.Text);
      }
      return themes;
    };
  }

  queryExtractAtXY(x, y) {
    this.extract_ = undefined;
    this.embeddable_ = undefined;
    this.x = x;
    this.y = y;
    const url =
      '/query_oereb_service?' +
      new URLSearchParams({
        X: x,
        Y: y,
        _dc: new Date().getTime(),
      });
    return fetch(url, {method: 'GET'})
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .catch((error) => Promise.reject(error))
      .then((data) => {
        const getExtractByIdResponse = data?.GetExtractByIdResponse;
        this.extract_ = getExtractByIdResponse?.extract;
        this.embeddable_ = getExtractByIdResponse?.embeddable;
        return getExtractByIdResponse;
      })
      .catch((error) => Promise.reject(error));
  }

  getExtractPDF() {
    return this.getExtract()?.ExtractPDF;
  }

  getExtract() {
    return this.extract_;
  }

  getRealEstate() {
    return this.getExtract()?.RealEstate;
  }

  validate_() {
    // TODO 13-03-2023 cvr: is this check relevant?
    // There are parcels in GL without any RestrictionOnLandownership -> the extract is not displayed

    // Check for restrictions to be an array
    const restrictions = this.getRealEstate().RestrictionOnLandownership;
    if (!Array.isArray(restrictions)) {
      return false;
    }

    // Check for restrictions without legal provisions
    restrictions.forEach((restriction) => {
      const legalProvisions = restriction?.LegalProvision;
      if (!Array.isArray(legalProvisions)) {
        return false;
      } else if (legalProvisions.length === 0) {
        return false;
      }
    });

    return true;
  }

  getConcernedThemes() {
    return this.filterThemeTextsByLanguage(this.getExtract()?.ConcernedTheme);
  }

  getNotConcernedThemes() {
    return this.filterThemeTextsByLanguage(this.getExtract()?.NotConcernedTheme);
  }

  getThemesWithoutData() {
    return this.filterThemeTextsByLanguage(this.getExtract()?.getThemesWithoutData);
  }

  getRestrictions(themeCode) {
    const realEstate = this.getRealEstate();
    if (realEstate == undefined) {
      return undefined;
    }
    return realEstate.RestrictionOnLandownership.filter(
      (restriction) => restriction.Theme.Code === themeCode
    );
  }

  getLegend(themeCode) {
    const restrictions = this.getRestrictions(themeCode);
    if (restrictions == undefined) {
      return undefined;
    }
    const legendEntries = [];
    const legendGraphics = [];
    for (let i = 0; i < restrictions.length; i++) {
      let existing = false;
      for (let j = 0; j < legendEntries.length; j++) {
        if (legendEntries[j]['TypeCode'] === restrictions[i]['TypeCode']) {
          existing = true;
          if (restrictions[i]['NrOfPoints'] != undefined) {
            legendEntries[j]['NrOfPoints'] += restrictions[i]['NrOfPoints'];
          }
          if (restrictions[i]['LengthShare'] != undefined) {
            legendEntries[j]['LengthShare'] += restrictions[i]['LengthShare'];
          }
          if (restrictions[i]['AreaShare'] != undefined) {
            legendEntries[j]['AreaShare'] += restrictions[i]['AreaShare'];
          }
          if (restrictions[i]['PartInPercent'] != undefined) {
            legendEntries[j]['PartInPercent'] += restrictions[i]['PartInPercent'];
          }
          break;
        }
      }
      if (!existing) {
        const legendEntry = {
          'TypeCode': restrictions[i]['TypeCode'],
          'LegendText': restrictions[i]['LegendText'] || restrictions[i]['Information'], // TODO 18-04-2023 cvr: backward compatibility v1
          'SymbolRef': restrictions[i]['SymbolRef'],
          'SubTheme': restrictions[i]['SubTheme'],
        };
        if (restrictions[i]['NrOfPoints'] != undefined) {
          legendEntry['NrOfPoints'] = restrictions[i]['NrOfPoints'];
        }
        if (restrictions[i]['LengthShare'] != undefined) {
          legendEntry['LengthShare'] = restrictions[i]['LengthShare'];
        }
        if (restrictions[i]['AreaShare'] != undefined) {
          legendEntry['AreaShare'] = restrictions[i]['AreaShare'];
        }
        if (restrictions[i]['PartInPercent'] != undefined) {
          legendEntry['PartInPercent'] = restrictions[i]['PartInPercent'];
        }
        legendEntries.push(legendEntry);
      }
      if (typeof restrictions[i]['Map']['LegendAtWeb'] === 'string') {
        this.addIfNotContains_(restrictions[i]['Map']['LegendAtWeb'], legendGraphics);
      }
    }
    return {
      'entries': legendEntries,
      'graphics': legendGraphics,
    };
  }

  addIfNotContains_(item, target) {
    if (Array.isArray(target)) {
      if (!target.includes(item)) {
        target.push(item);
      }
    }
  }

  getDocuments(themeCode) {
    // Get the restrictions for the specified topic
    const restrictions = this.getRestrictions(themeCode);
    if (Array.isArray(restrictions)) {
      const documents = {
        'LegalProvision': [],
        'Law': [],
        'Hint': [],
      };
      // Iterate the resulting restrictions
      for (let i = 0; i < restrictions.length; i++) {
        // Iterate the legal provisions for each restriction
        const legalProvision = restrictions[i]['LegalProvisions'];
        for (let j = 0; j < legalProvision.length; j++) {
          // Create legal provision object with necessary properties and check if it needs to be added
          // TODO 18-04-2023 cvr: backward compatibility v1
          if (legalProvision[j].Type?.Code == undefined) {
            legalProvision[j].Type = {'Code': legalProvision[j].DocumentType};
          }
          this.addDocumentIfNotContained_(
            {
              'TypeCode': legalProvision[j]['Type']['Code'],
              'Title': legalProvision[j]['Title'],
              'Abbreviation': legalProvision[j]['Abbreviation'],
              'OfficialNumber': legalProvision[j]['OfficialNumber'],
              'ArticleNumber': legalProvision[j]['ArticleNumber'] || [],
              'Article': legalProvision[j]['Article'] || [],
              'TextAtWeb': [legalProvision[j]['TextAtWeb']],
            },
            documents
          );
          // Add possible references to the documents
          const references = legalProvision[j]['Reference'];
          if (Array.isArray(references)) {
            this.addDocumentsIfNotContained_(references, documents);
          }
        }
      }
      // Add possible references for the real estate to the documents
      this.addDocumentsIfNotContained_(this.getRealEstate()['Reference'], documents);
      return documents;
    }
    return undefined;
  }

  addDocumentIfNotContained_(document, target) {
    const targetArray = target[document['TypeCode']];
    if (Array.isArray(targetArray)) {
      for (let i = 0; i < targetArray.length; i++) {
        // Check if document already exists (only use 'Title', 'OfficialNumber' and 'TextAtWeb' for comparison)
        if (
          targetArray[i]['Title'] === document['Title'] &&
          targetArray[i]['OfficialNumber'] === document['OfficialNumber']
        ) {
          // Add missing article numbers if document already exists
          for (let j = 0; j < document['ArticleNumber'].length; j++) {
            this.addIfNotContains_(document['ArticleNumber'][j], targetArray[i]['ArticleNumber']);
          }
          // Add missing articles if document already exists
          for (let k = 0; k < document['Article'].length; k++) {
            this.addIfNotContains_(document['Article'][k], targetArray[i]['Article']);
          }
          // Add missing files if document already exists
          for (let l = 0; l < document['TextAtWeb'].length; l++) {
            this.addIfNotContains_(document['TextAtWeb'][l], targetArray[i]['TextAtWeb']);
          }
          return;
        }
      }
      // Add document if it is missing
      targetArray.push(document);
    }
  }

  addDocumentsIfNotContained_(documents, target) {
    // Iterate documents to be added
    if (Array.isArray(documents) && target != undefined) {
      for (let i = 0; i < documents.length; i++) {
        // Create document object with necessary properties and check if it needs to be added
        this.addDocumentIfNotContained_(
          {
            'TypeCode': documents[i]['TypeCode'],
            'Title': documents[i]['Title'],
            'Abbreviation': documents[i]['Abbreviation'],
            'OfficialNumber': documents[i]['OfficialNumber'],
            'ArticleNumber': documents[i]['ArticleNumber'] || [],
            'Article': documents[i]['Article'] || [],
            'TextAtWeb': [documents[i]['TextAtWeb']],
          },
          target
        );
        // Do the same for possible references
        const references = documents[i]['Reference'];
        if (Array.isArray(references)) {
          this.addDocumentsIfNotContained_(references, target);
        }
      }
    }
  }

  getResponsibleOffices(themeCode) {
    const restrictions = this.getRestrictions(themeCode);
    if (Array.isArray(restrictions)) {
      const offices = [];
      for (let i = 0; i < restrictions.length; i++) {
        this.addIfNotContains_(restrictions[i]['ResponsibleOffice'], offices);
        if (Array.isArray(restrictions[i]['Geometry'])) {
          for (let j = 0; j < restrictions[i]['Geometry'].length; j++) {
            this.addIfNotContains_(restrictions[i]['Geometry'][j]['ResponsibleOffice'], offices);
          }
        }
      }
      return offices;
    }
    return undefined;
  }
}

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('Extract', [multilingualTextFilter.name]);
myModule.service('Extract', ExtractService);

export default myModule;
