import angular from 'angular';
import Extract from '../service/extract.js';
import {oerebEventExtractLoaded} from '../component.js';
export class staticExtractController {
  /**
   *
   * @param {angular.IScope} $scope Scope.
   * @param {import("../service/extract.js").ExtractService} Extract The service for extract handling.
   *
   * @ngInject
   */

  constructor($scope, Extract) {
    this.extract_ = Extract;
    this.urlStaticExtract_ = undefined;

    // Update link to PDF extract when extract is loaded
    $scope.$on(oerebEventExtractLoaded, () => {
      this.urlStaticExtract_ = this.extract_.getExtractPDF();
    });
  }
}

const staticExtractComponent = {
  controller: staticExtractController,
  template: require('./static_extract.html'),
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('agrolaOerebStaticExtract', [Extract.name]);
myModule.component('agrolaOerebStaticExtract', staticExtractComponent);

export default myModule;
