import angular from 'angular';

/**
 * @constructor
 *
 * @description
 *
 * Angular filter definition function.
 *
 * @returns {angular.Filter} Angular filter function.
 *
 * @ngInject
 */
export function replaceFilter() {
  return function (input, target, replacement) {
    if (typeof input === 'string') {
      return input.replace(new RegExp(target, 'g'), replacement);
    }
    return input;
  };
}

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('replace', []);
myModule.filter('replace', replaceFilter);

export default myModule;
