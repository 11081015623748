import angular from 'angular';
import Extract from '../service/extract.js';
import multilingualTextFilter from '../filters/multilingual_text.js';
export class legalDocumentController {
  /**
   *
   * @param {import("../service/extract.js").ExtractService} Extract The service for the extract handling.
   * @param {import("../filters/multilingual_text.js").multilingualTextFilter} multilingualTextFilter
   *
   * @ngInject
   */
  constructor(Extract, multilingualTextFilter) {
    /** @export {Object|undefined} */
    this.data;

    this.themeCode;

    this.extract = Extract;

    this.getTitle = function (record) {
      let title = multilingualTextFilter(record['Title']);
      if (Array.isArray(record['Abbreviation']) && record['Abbreviation'].length > 0) {
        title += ' (' + multilingualTextFilter(record['Abbreviation']) + ')';
      }
      if (typeof record['OfficialNumber'] === 'string') {
        title += ', ' + record['OfficialNumber'];
      }
      return title;
    };
  }

  $onInit() {
    this.data = this.extract.getDocuments(this.themeCode);
  }
}

/**
 * @ngdoc component
 * @description
 *
 * Element containing the legal documents for a specified topic.
 *
 * @param {string} themeCode The code of the theme to show the legal documents for.
 */
const legalDocumentComponent = {
  bindings: {
    themeCode: '=',
  },
  controller: legalDocumentController,
  template: require('./legal_documents.html'),
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('oerebLegalDocuments', [Extract.name, multilingualTextFilter.name]);
myModule.component('oerebLegalDocuments', legalDocumentComponent);

export default myModule;
