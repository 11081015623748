import angular from 'angular';
import Extract from '../service/extract.js';
import replaceFilter from '../filters/replace.js';
import sortLegendFilter from '../filters/sort_legend.js';
import multilingualTextFilter from '../filters/multilingual_text.js';

export class legendController {
  /**
   * @param {angular.$timeout} $timeout Angular $timeout service.
   * @param {angular.IRootElementService} $element Element.
   * @param {import("../service/extract.js").ExtractService} Extract Service for extract handling.
   *
   * @ngInject
   */

  constructor($timeout, $element, Extract) {
    const graphicsTextHidden = 'vollständige Legende anzeigen';
    const graphicsTextShown = 'vollständige Legende verbergen';
    const graphicsIconHidden = 'fa-chevron-down';
    const graphicsIconShown = 'fa-chevron-up';

    const fullLegend = $element.find('.full-legend').first();

    fullLegend.collapse({
      toggle: false,
    });

    /**
     * @type {string}
     */
    this.themeCode;

    /** @export {string} */
    this.graphicsText = graphicsTextHidden;

    /** @export {string} */
    this.graphicsIcon = graphicsIconHidden;

    /** @export {Object} */
    this.legend;

    this.extract = Extract;

    /**
     * @ngdoc method
     * @name oerebLegend#showGraphics
     *
     * @description
     *
     * Display legend graphics section only if it contains at least one graphic.
     *
     * @return {boolean} True, if there is at least one legend graphic available.
     *
     * @export
     */
    this.showGraphics = () => {
      const graphics = this.legend?.graphics;
      return Array.isArray(graphics) && graphics.length > 0;
    };

    /**
     * @ngdoc method
     * @name oerebLegend#toggleGraphics
     *
     * @description
     *
     * Show/hide the legend graphics.
     *
     * @export
     */
    this.toggleGraphics = function () {
      fullLegend.collapse('toggle');
    };

    // Change icon and text if graphics are shown
    fullLegend.on('show.bs.collapse', (evt) => {
      if (fullLegend[0] === evt.target) {
        $timeout(function () {
          this.graphicsText = graphicsTextShown;
          this.graphicsIcon = graphicsIconShown;
        });
      }
    });

    // Change icon and text if graphics are hidden
    fullLegend.on('hide.bs.collapse', (evt) => {
      if (fullLegend[0] === evt.target) {
        $timeout(function () {
          this.graphicsText = graphicsTextHidden;
          this.graphicsIcon = graphicsIconHidden;
        });
      }
    });
  }

  $onInit() {
    this.legend = this.extract.getLegend(this.themeCode);
  }
}

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('oerebLegend', [
  Extract.name,
  replaceFilter.name,
  sortLegendFilter.name,
  multilingualTextFilter.name,
]);

myModule.component('oerebLegend', {
  bindings: {
    themeCode: '<',
  },
  controller: legendController,
  template: require('./legend.html'),
});

export default myModule;
