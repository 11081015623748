import angular from 'angular';
import Extract from '../service/extract.js';
import multilingualTextFilter from '../filters/multilingual_text.js';

/**
 *
 * @param {import("../service/extract.js").ExtractService} Extract The service for the extract handling.
 *
 * @ngInject
 */
export class responsibleOfficeController {
  constructor(Extract) {
    this.themeCode;
    this.extract = Extract;

    /** @export {Array|undefined} */
    this.data;
  }

  $onInit() {
    this.data = this.extract.getResponsibleOffices(this.themeCode);
  }
}

const responsibleOfficeComponent = {
  bindings: {
    themeCode: '=',
  },
  controller: responsibleOfficeController,
  template: require('./responsible_offices.html'),
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('oerebResponsibleOffices', [Extract.name, multilingualTextFilter.name]);
myModule.component('oerebResponsibleOffices', responsibleOfficeComponent);

export default myModule;
