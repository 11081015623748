import angular from 'angular';
import Extract from '../service/extract.js';
import {oerebEventExtractLoaded} from '../component.js';

export class notConcernedThemesController {
  /**
   *
   * @param {angular.IScope} $scope Scope.
   * @param {angular.IRootElementService} $element Element.
   * @param {angular.$timeout} $timeout Angular $timeout service.
   * @param {import("../service/extract.js").ExtractService} Extract The service for extract handling.
   *
   * @ngInject
   */
  constructor($scope, $element, $timeout, Extract) {
    this.badgeIconCollapsed = 'fa-chevron-down';
    this.badgeIconExpanded = 'fa-chevron-up';

    /** @export {string} */
    this.id = 'not-concerned-' + parseInt(Math.random() * Date.now());

    /** @export {string} */
    this.badgeIcon = this.badgeIconCollapsed;

    /** @export {Array} */
    this.not_concerned = [];

    // Update data on loaded extract
    $scope.$on(oerebEventExtractLoaded, () => {
      $timeout(() => {
        this.not_concerned = Extract.getNotConcernedThemes();
      });
    });

    // Get collapsible element
    const collapsible = $element.find('.collapse').first();

    // Listen on show event to switch badge icon
    collapsible.on('show.bs.collapse', (evt) => {
      if (evt.target.id === this.id + '-collapse') {
        $timeout(() => {
          this.badgeIcon = this.badgeIconExpanded;
        });
      }
    });

    // Listen on hide event to switch badge icon
    collapsible.on('hide.bs.collapse', (evt) => {
      if (evt.target.id === this.id + '-collapse') {
        $timeout(() => {
          this.badgeIcon = this.badgeIconCollapsed;
        });
      }
    });

    /** @export */
    this.toggle = () => {
      if (this.not_concerned.length > 0) {
        collapsible.collapse('show');
        this.toggledGroup = 'NotConcernedTheme';
      }
    };

    $scope.$watch(this.toggledGroup, (value) => {
      if (value !== 'NotConcernedTheme') {
        collapsible.collapse('hide');
      }
    });
  }
}

const notConcernedThemesComponent = {
  bindings: {
    /** @export */ toggledGroup: '=',
  },
  controller: notConcernedThemesController,
  template: require('./not_concerned_themes.html'),
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('agrolaOerebNotConcernedTheme', [Extract.name]);
myModule.component('agrolaOerebNotConcernedTheme', notConcernedThemesComponent);

export default myModule;
