import angular from 'angular';
import Extract from '../service/extract.js';
import {oerebEventExtractLoaded} from '../component.js';
export class concernedThemesController {
  /**
   *
   * @param {angular.IScope} $scope Scope.
   * @param {angular.IRootElementService} $element Element.
   * @param {angular.$timeout} $timeout Angular $timeout service.
   * @param {import("../service/extract.js").ExtractService} Extract The service for extract handling.
   *
   * @ngInject
   */
  constructor($scope, $element, $timeout, Extract) {
    this.badgeIconCollapsed = 'fa-chevron-down';
    this.badgeIconExpanded = 'fa-chevron-up';

    /** @export {string} */
    this.id = 'concerned-' + parseInt(Math.random() * Date.now());

    /** @export {string} */
    this.badgeIcon = this.badgeIconCollapsed;

    /** @export {Array} */
    this.concerned = [];

    this.selectedTheme;

    // Update concerned themes on loaded extract
    $scope.$on(oerebEventExtractLoaded, () => {
      this.concerned = Extract.getConcernedThemes();
      if (this.concerned.length > 0) {
        this.selectedTheme = this.concerned[0]['Code'];
      }
      this.toggle();
    });

    // Get collapsible element
    const collapsible = $element.find('.collapse').first();

    // Listen on show event to switch badge icon
    collapsible.on('show.bs.collapse', (evt) => {
      if (evt.target.id === this.id + '-collapse') {
        $timeout(() => {
          this.badgeIcon = this.badgeIconExpanded;
        });
      }
    });

    // Listen on hide event to switch badge icon
    collapsible.on('hide.bs.collapse', (evt) => {
      if (evt.target.id === this.id + '-collapse') {
        $timeout(() => {
          this.badgeIcon = this.badgeIconCollapsed;
        });
      }
    });

    /** @export */
    this.toggle = () => {
      if (this.concerned.length > 0) {
        collapsible.collapse('show');
        this.toggledGroup = 'ConcernedTheme';
      }
    };

    $scope.$watch(this.toggledGroup, (value) => {
      if (value !== 'ConcernedTheme') {
        collapsible.collapse('hide');
      }
    });
  }
}

const concernedThemesComponent = {
  bindings: {
    toggledGroup: '=',
  },
  controller: concernedThemesController,
  template: require('./concerned_themes.html'),
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('agrolaOerebConcernedTheme', [Extract.name]);
myModule.component('agrolaOerebConcernedTheme', concernedThemesComponent);

export default myModule;
