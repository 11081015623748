import angular from 'angular';
import {oerebDefaultLanguage} from '../component.js';

/**
 * @constructor
 *
 * @description
 *
 * Angular filter definition function.
 *
 * @returns {angular.Filter} Angular filter function.
 *
 * @ngInject
 */
export function multilingualTextFilter() {
  return function (input, language) {
    if (Array.isArray(input) && input.length > 0) {
      let result;
      if (typeof language === 'string') {
        for (let i = 0; i < input.length; i++) {
          if (input[i]['Language'] === language) {
            result = input[i]['Text'];
            break;
          }
        }
      }
      if (result == undefined) {
        for (let j = 0; j < input.length; j++) {
          if (input[j]['Language'] === oerebDefaultLanguage) {
            result = input[j]['Text'];
            break;
          }
        }
      }
      if (result == undefined) {
        result = input[0]['Text'];
      }
      return result;
    }
    return input?.Text || input;
  };
}

/**
 * @type {angular.IModule}
 * @hidden
 *
 * @description
 *
 * Extracts the specified language or default language from a multilingual array.
 *
 * - If no language is specified, the default language will be used.
 * - If no language matches, the first element will be returned
 *
 *  * @param {string} language The language to extract.
 */
const myModule = angular.module('multilingualText', []);
myModule.filter('multilingualText', multilingualTextFilter);

export default myModule;
