/**
 * @module agrola_gmf.oereb_tool.component
 */
import {Draw} from 'ol/interaction.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';
import ngeoMiscToolActivate from 'ngeo/misc/ToolActivate.js';
import angular from 'angular';
import Extract from './service/extract.js';
import StaticExtract from './components/static_extract.js';
import RealEstate from './components/real_estate.js';
import Legend from './components/legend.js';
import ConcernedTheme from './components/concerned_theme.js';
import NotConcernedTheme from './components/not_concerned_theme.js';
import ThemeWithoutData from './components/themes_without_data.js';
import LegalDocuments from './components/legal_documents.js';
import ResponsibleOffices from './components/responsible_offices.js';
import Topic from './components/topic.js';

//import '../apps/sass/oereb.scss';

export const oerebEventExtractLoaded = 'oereb-event-extract-loaded';
export const oerebEventExtractClosed = 'oereb-event-extract-closed';
export const oerebEventEgridSelected = 'oereb-event-egrid-selected';
export const oerebDefaultLanguage = 'de';
class Controller {
  /**
   * @param {angular.$http} $http Angular Http service.
   * @param {angularGettext.Catalog} gettextCatalog Gettext catalog.
   * @param {angular.Scope} $scope Scope.
   * @param {ngeo.misc.ToolActivateMgr} ngeoToolActivateMgr ngeoToolActivateMgr.
   * @param {import("./service/extract.js").ExtractService} Extract Angular service for extract loading.
   * @ngInject
   */
  constructor($http, gettextCatalog, $scope, ngeoToolActivateMgr, Extract) {
    /**
     * @type {angular.$http}
     * @private
     */
    this.http_ = $http;

    /**
     * @type {angularGettext.Catalog}
     * @private
     */
    this.gettextCatalog = gettextCatalog;

    /**
     * @type {angular.Scope}
     * @private
     */
    this.scope_ = $scope;

    /**
     * The ngeo ToolActivate manager service.
     * @type {ngeo.misc.ToolActivateMgr}
     */
    this.ngeoToolActivateMgr = ngeoToolActivateMgr;

    /**
     * @type {!ol.Map}
     */
    this.map;

    /**
     * @type {object}
     */
    this.iconcallback;

    /**
     * @type {boolean}
     */
    this.panelactive;

    /**
     * @type {string}
     */
    this.username;

    /**
     * @type {ol.Source}
     */
    this.source;

    /**
     * @type {ol.Layer}
     */
    this.vector;

    /**
     * @type {import("./service/extract.js").ExtractService}
     * @private
     */
    this.ExtractService_ = Extract;

    /** @export {boolean} */
    this.extractActive = false;

    /** @export {boolean} */
    this.extractCollapsed = false;

    /** @export {boolean} */
    this.informationActive = false;

    /** @export {boolean} */
    this.errorActive = false;

    /** @export {function} */
    this.retryCallback = function () {};

    /** @export {boolean} */
    this.loading = false;

    /** @export {string} */
    this.toggledGroup = undefined;
  }

  $onInit() {
    this.source = new VectorSource();
    this.vector = new VectorLayer({
      source: this.source,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
    });
    this.parser = new DOMParser();

    // init all interaction and tools
    this.initInteractions();
  }

  $onChanges() {
    if (this.panelactive) {
      this.addInteractions();
    }
  }

  initInteractions() {
    this.drawPoint = new Draw({
      source: this.source,
      type: 'Point',
    });
    this.drawPoint.on(
      'drawend',
      (e) => {
        this.queryExternalService(e.feature);
      },
      this
    );
    this.drawPointTool = new ngeoMiscToolActivate(this.drawPoint, 'agrolaOerebPanelActive');
    this.ngeoToolActivateMgr.registerTool('mapTools', this.drawPointTool, false);
    this.ngeoToolActivateMgr.deactivateTool(this.drawPointTool);
  }

  addInteractions() {
    this.map.addInteraction(this.drawPoint);
    this.ngeoToolActivateMgr.activateTool(this.drawPointTool);
  }

  /**
   * @param {number} x
   * @param {number} y
   * @return {Promise}
   */
  getExtractAtXY(x, y) {
    return this.ExtractService_.queryExtractAtXY(x, y).then(() => {
      this.extractActive = typeof this.ExtractService_.getExtract() !== 'undefined';
      this.scope_.$broadcast(oerebEventExtractLoaded);
    });
  }

  /**
   * @param {Object} feature
   */
  queryExternalService(feature) {
    const coordinates = feature.getGeometry().flatCoordinates;
    this.getExtractAtXY(coordinates[0], coordinates[1]).catch((error) => {
      console.error('Extract request or validation failed: ' + error);
    });

    setTimeout(() => {
      // clean up last drawing, delayed because otherwise the new drawing is added after the cleanup
      this.source.clear();
    }, 0);
  }

  closeExtract() {
    this.informationActive = false;
    this.extractActive = false;
    this.errorActive = false;
    this.$scope_.$broadcast(this.oerebEventExtractClosed_);
    this.$location_.search('egrid', null);
  }

  collapseExtract() {
    this.informationActive = false;
    this.extractActive = false;
    this.errorActive = false;
    this.extractCollapsed = true;
  }

  expandExtract() {
    this.extractCollapsed = false;
    this.informationActive = false;
    this.errorActive = false;
    this.extractActive = true;
  }

  toggleInformation() {
    this.informationActive = !this.informationActive;
  }
}

/**
 * @type {!angular.Module}
 */
const agrolaOerebModule = angular.module('agrolaOerebTool', [
  'ngSanitize',
  Extract.name,
  RealEstate.name,
  StaticExtract.name,
  ConcernedTheme.name,
  NotConcernedTheme.name,
  ThemeWithoutData.name,
  Topic.name,
  Legend.name,
  LegalDocuments.name,
  ResponsibleOffices.name,
]);

agrolaOerebModule.component('agrolaOerebTool', {
  bindings: {
    'map': '<',
    'panelactive': '<',
    'username': '<',
  },
  controller: Controller,
  template: require('./oerebtool.html'),
});

export default agrolaOerebModule;
