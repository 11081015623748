import angular from 'angular';
import {MultiPolygon} from 'ol/geom.js';
import Feature from 'ol/Feature.js';
import Extract from '../service/extract.js';
import {oerebEventExtractLoaded, oerebEventExtractClosed, oerebEventEgridSelected} from '../component.js';

export class topicController {
  /**
   * @param {angular.Scope} $scope The current scope.
   * @param {angular.Scope} $rootScope The application root scope.
   * @param {import("../service/extract.js").ExtractService} Extract Angular service for extract loading.
   *
   * @constructor
   * @ngInject
   */

  constructor($scope, $rootScope, Extract) {
    this.scope_ = $scope;
    this.$rootScope_ = $rootScope;
    this.ExtractService_ = Extract;
    this.oerebEventEgridSelected_ = oerebEventEgridSelected;
    this.oerebEventExtractLoaded_ = oerebEventExtractLoaded;
    this.oerebEventExtractClosed_ = oerebEventExtractClosed;

    /** @export {string} */
    this.selectedTheme = undefined;

    /**
     * @type {object}
     */
    this.theme;

    this.id = 'topic-' + parseInt(Math.random() * Date.now());

    // Update intially selected topic on loaded extract
    this.scope_.$on(
      this.oerebEventExtractLoaded_,
      function () {
        const concernedThemes = this.ExtractService_.getConcernedThemes();
        if (concernedThemes.length > 0) {
          this.selectedTheme = concernedThemes[0]['Code'];
        }
      }.bind(this)
    );
  }

  getRealEstateFeature_() {
    const coordinates = this.ExtractService_.getRealEstate()?.Limit?.coordinates;
    if (coordinates == undefined) {
      return;
    }
    const geom = new MultiPolygon(coordinates);
    return new Feature(geom);
  }
}

const topicComponent = {
  bindings: {
    'theme': '<',
  },
  controller: topicController,
  template: require('./topic.html'),
};

topicController.$inject = ['$scope', '$rootScope', Extract.name];

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('oerebTopic', [Extract.name]);
myModule.controller('topicController', topicController);
myModule.component('oerebTopic', topicComponent);

export default myModule;
