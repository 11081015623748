import angular from 'angular';
import Extract from '../service/extract.js';
import {oerebEventExtractLoaded} from '../component.js';

export class themeWithoutDataController {
  /**
   *
   * @param {angular.IScope} $scope Scope.
   * @param {angular.IRootElementService} $element Element.
   * @param {angular.$timeout} $timeout Angular $timeout service.
   * @param {import("../service/extract.js").ExtractService} Extract The service for extract handling.
   *
   * @ngInject
   */
  constructor($scope, $element, $timeout, Extract) {
    this.badgeIconCollapsed = 'fa-chevron-down';
    this.badgeIconExpanded = 'fa-chevron-up';

    /** @export {string} */
    this.id = 'no-data-' + parseInt(Math.random() * Date.now());

    /** @export {string} */
    this.badgeIcon = this.badgeIconCollapsed;

    /** @export {Array} */
    this.no_data = [];

    // Update data on loaded extract
    $scope.$on(oerebEventExtractLoaded, () => {
      this.no_data = Extract.getThemesWithoutData();
    });

    // Get collapsible element
    const collapsible = $element.find('.collapse').first();

    // Listen on show event to switch badge icon
    collapsible.on('show.bs.collapse', (evt) => {
      if (evt.target.id === this.id + '-collapse') {
        $timeout(() => {
          this.badgeIcon = this.badgeIconExpanded;
        });
      }
    });

    // Listen on hide event to switch badge icon
    collapsible.on('hide.bs.collapse', (evt) => {
      if (evt.target.id === this.id + '-collapse') {
        $timeout(() => {
          this.badgeIcon = this.badgeIconCollapsed;
        });
      }
    });

    /** @export */
    this.toggle = () => {
      if (this.no_data.length > 0) {
        collapsible.collapse('show');
        this.toggledGroup = 'ThemeWithoutData';
      }
    };

    $scope.$watch(this.toggledGroup, (value) => {
      if (value !== 'ThemeWithoutData') {
        collapsible.collapse('hide');
      }
    });
  }
}

const themeWithoutDataComponent = {
  bindings: {
    /** @export */ toggledGroup: '=',
  },
  controller: themeWithoutDataController,
  template: require('./themes_without_data.html'),
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('agrolaOerebThemeWithoutData', [Extract.name]);
myModule.component('agrolaOerebThemeWithoutData', themeWithoutDataComponent);

export default myModule;
